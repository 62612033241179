<p-treeTable [value]="_treeNode" [style]="{'margin-top':'20px'}" >
  <ng-template pTemplate="header">
    <tr>
      <th align="left">Position Km</th>
      <th width="80px">RL</th>
      <th width="80px">RL Min</th>
      <th width="80px">RL Max</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr>
      <td>
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        {{rowData.elementDisplay}}
      </td>
      <td align="right">
        {{rowData.elementQuality | number:'1.0-0'}}
      </td>
      <td align="right">
        {{rowData.RL_Min| number:'1.0-0'}}
      </td>
      <td align="right">
        {{rowData.RL_Max | number:'1.0-0'}}
      </td>
    </tr>
  </ng-template>
</p-treeTable>

