import {Injectable} from '@angular/core';
import {Session} from "../globals/session";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {FileTypesEnum} from "../enums/file-types-enum.enum";
import {FeatureModel} from "../models/geojson/feature-model";
import {FeatureObjectElementModel} from "../models/geojson/feature-object-element-model";
import {ElementGroupGroundPipe} from "../globals/pipes/element-group-ground.pipe";
import {ElementNameGroundPipe} from "../globals/pipes/element-name-ground.pipe";
import {ElementAxeGroundPipe} from "../globals/pipes/element-axe-ground.pipe";
import {ElementTypesEnum} from "../enums/element-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private session: Session,
              private groundPipe: ElementGroupGroundPipe,
              private nameGroundPipe: ElementNameGroundPipe,
              private axeGroundPipe: ElementAxeGroundPipe) {
  }


  public exportFeaturesAsHeliosExcelFile(features: FeatureModel[], excelFileName: string): void {
    let content = this.featuresToHeliosExcel(features);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
    worksheet['!cols'] = this.fitToColumn(content[0]);

    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportFeaturesAsExcelFile(features: FeatureModel[], excelFileName: string): void {
    let content = this.featuresToExcel(features);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
    worksheet['!cols'] = this.fitToColumn(content[0]);

    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportFeaturesAsJSONFile(features: FeatureModel[], jsonFileName: string): void {
    let string = JSON.stringify(features, null, 4);
    let featureCollectionString = "{\n" +
      "\t\"type\" : \"FeatureCollection\",\n" +
      "\t\"features\" : " + string + "}";

    const blob = new Blob([featureCollectionString], {type: FileTypesEnum.JSON_TYPE});
    FileSaver.saveAs(blob, jsonFileName + '_export_' + new Date().getTime() + FileTypesEnum.JSON_EXTENSION);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: FileTypesEnum.EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + FileTypesEnum.EXCEL_EXTENSION);
  }

  private featuresToExcel(features: FeatureModel[]): any {
    let result: any[] = [];
    features.forEach(feature => {
      result.push(feature.toExcel());
    });
    return result;
  }

  private featuresToHeliosExcel(features: FeatureModel[]): any {
    let result: any[] = [];

    features.forEach(feature => {
      if (feature.geometry.type === undefined && feature.geometry.coordinates === undefined) {
        feature.geometry.type = feature['_geometry'].type;
        feature.geometry.coordinates = feature['_geometry'].coordinates;
      }
      if (feature.properties['elementTimestamp'] === undefined) {
        feature.properties['elementTimestamp'] = feature.properties['element_timestamp'];
      }
      let item = feature.toExcel();
      let itemHelios: any = {};
      let itemProperties: FeatureObjectElementModel = feature.properties as FeatureObjectElementModel;

      if (itemProperties.elementType === ElementTypesEnum.ELEMENT_TYPE_GROUND || itemProperties.elementType === ElementTypesEnum.ELEMENT_TYPE_LANE) {
        itemHelios['Catégorie'] = item['elementCategory']; //this.groundPipe.transform(item['elementCategory']);
        itemHelios['Elément'] = item['elementName'];
        itemHelios['Qualité (%)'] = item['elementQuality'];
        itemHelios['Détection'] = item['elementTimestamp'];
        itemHelios['Couleur'] = item['qualificationColour'];
        itemHelios['Taille'] = item['qualificationSize'];
        itemHelios['Direction'] = item['qualificationDirection']; //this.axeGroundPipe.transform(item['qualificationDirection']);
        itemHelios['Type de produit'] = item['qualificationPainting']; //this.axeGroundPipe.transform(item['qualificationPainting']);
        itemHelios['Longitude'] = item['geoLongitude'];
        itemHelios['Latitude'] = item['geoLatitude'];
        itemHelios['Fin Longitude'] = item['geoLongitudeEnd'];
        itemHelios['Fin Latitude'] = item['geoLatitudeEnd'];
        itemHelios['Axe'] = item['qualificationAxis']; //this.axeGroundPipe.transform(item['qualificationAxis']);
        itemHelios['Quantité'] = item['elementCount'];
        itemHelios['Mètre linéaire'] = item['metricsLinearLength'];
        itemHelios['Mètre peint'] = item['metricsPaintedLength'];
        itemHelios['Surface (m²)'] = item['metricsSurface'];
        itemHelios['Surface peinte (m²)'] = item['metricsPaintedSurface'];
        itemHelios['Méthode Facturation'] = item['elementPricingMethod'];
        itemHelios['Estimation de prix'] = item['elementPricingValue'];

      } else if (itemProperties.elementType === ElementTypesEnum.ELEMENT_TYPE_SIGN) {
        itemHelios['Catégorie'] = item['elementCategory'];
        itemHelios['Elément'] = item['elementName'];
        itemHelios['Qualité (%)'] = item['elementQuality'];
        itemHelios['Détecte'] = item['elementTimestamp'];
        itemHelios['Année de fabrication'] = item['qualificationYearBuild'];
        itemHelios['Fabricant'] = item['qualificationBuilder'];
        itemHelios['Classe de film'] = item['qualificationFilmClass'];
        itemHelios['Implémentation'] = item['qualificationImplementType'];
        itemHelios['Ancrage'] = item['qualificationAnchor'];
        itemHelios['Taille'] = item['qualificationSize'];
        itemHelios['Sous-panneau'] = item['metricsHeightSp'];
        itemHelios['Longitude'] = item['geoLongitude'];
        itemHelios['Latitude'] = item['geoLatitude'];
        itemHelios['Fin Longitude'] = item['geoLongitudeEnd'];
        itemHelios['Fin Latitude'] = item['geoLatitudeEnd'];
        itemHelios['Méthode Facturation'] = item['elementPricingMethod'];
        itemHelios['Estimation de prix'] = item['elementPricingValue'];

      }

      result.push(itemHelios);
    });
    return result;
  }


  private fitToColumn(row) {

    let colsWidth: object[] = [];

    // get maximum character (key or value) of each column
    Object.keys(row).forEach(function (k) {
      let width: number = 25;
      if (row[k] !== undefined && row[k] !== null) {
        width = (k.length > row[k].toString().length ? k.length : row[k].toString().length);
      }
      colsWidth.push({wch: width});
    });

    return colsWidth;
  }
}
