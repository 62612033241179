export enum ElementTypesEnum {
  ELEMENT_TYPE_SIGN = 'SIGN',
  ELEMENT_TYPE_GROUND = 'GROUND',
  ELEMENT_TYPE_LANE = 'LANE',
  ELEMENT_TYPE_CRASH = 'CRASH',
  ELEMENT_TYPE_TRASH = 'TRASH',

  ELEMENT_TYPE_SIGN_FR = 'sign-fr',
  ELEMENT_TYPE_REFLECTO = 'REFLECTO',

}
