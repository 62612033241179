import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Session} from "../../../globals/session";
import {FeatureModel} from "../../../models/geojson/feature-model";
import {ExportService} from "../../../services/export.service";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-dialog-ground',
  templateUrl: './dialog-ground.component.html',
  styleUrls: ['./dialog-ground.component.css']
})
export class DialogGroundComponent implements OnInit {

  @Input() features:FeatureModel[] = [];

  @Output() visibleChange = new EventEmitter<boolean>();

  lightFeatures: FeatureModel[];
  _visible: boolean = false;
  items: MenuItem[];

  constructor(public session: Session,
              private exportService: ExportService) {

  }

  ngOnInit() {
    this.items = [
      {label: 'Exporter JSON', icon: 'pi pi-save',command: () => {
          this.exportJSON();
        }}]
  }

  @Input()
  set visible(show: boolean) {
    this._visible = show;
    this.visibleChange.emit(this._visible)
  }
  get visible(): boolean {
    return this._visible;
  }

  show(show: boolean) {
    this.visible = show;
  }

  exportExcel() {
    this.exportService.exportFeaturesAsHeliosExcelFile(this.features, "ground");
  }
  exportJSON(){
    this.lightFeatures = [];
    this.features.forEach(feature => {
      if (feature.geometry.type === undefined && feature.geometry.coordinates === undefined) {
        feature.geometry.type = feature['_geometry'].type;
        feature.geometry.coordinates = feature['_geometry'].coordinates;
      }
      if (feature.properties['elementTimestamp'] === undefined) {
        feature.properties['elementTimestamp'] = feature.properties['element_timestamp'];
      }

      delete feature.properties['layout_text_font'];
      delete feature.properties['layout_text_size'];
      delete feature.properties['layout_text_color'];
      delete feature.properties['layout_line_cap'];
      delete feature.properties['layout_line_join'];
      delete feature.properties['layout_line_color'];
      delete feature.properties['layout_line_width'];
      delete feature.properties['layout_line_opacity'];
      delete feature.properties['layout_circle_color'];
      delete feature.properties['layout_circle_radius'];
      delete feature.properties['element_properties'];

      let lightFeature = new FeatureModel();
      lightFeature.geometry = feature.geometry;
      lightFeature.properties = feature.properties;
      this.lightFeatures.push(lightFeature);
    });
    this.exportService.exportFeaturesAsJSONFile(this.lightFeatures, "ground");
  }
}
