import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from './services/session.service';

import {Session} from "./globals/session";
import {StringUtils} from "./helpers/string.utils";
import {SpinnerComponent} from "./components/main/spinner/spinner.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  @ViewChild(SpinnerComponent, {static: true})
  private spinner: SpinnerComponent;

  constructor(public session: Session,
              private sessionService: SessionService) {
  }

  title = 'CityRoad';

  ngOnInit(): void {
    // let token: string = this.getUrlParam('token', '');
    // if (StringUtils.isNotEmpty(token)) {
    //   this.sessionService.getSessionByToken(token);
    // } else if (window.sessionStorage.length > 0){
    //   this.session.sessionIdentifier = window.sessionStorage.getItem('sessionIdentifier');
    //   this.session.token = window.sessionStorage.getItem('token');
    //   this.session.currentSession = JSON.parse(window.sessionStorage.getItem('currentSession'));
    //   this.session.isLogged = true;
    //   this.session.isLogging = false;
    //   this.session.sessionLocale = this.session.currentSession.user.userLocale;
    //   this.session.sessionProperties = this.session.currentSession.user.userProperties;
    // } else {
    //   this.session.isLogging = false;
    // }
  }

  // get values from Url
  getUrlVars() {
    const vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      return vars[key] = value;
    });
    return vars;
  }

  // add values by default
  getUrlParam(parameter, defaultvalue) {
    let urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

}
