import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {ConnectionOriginEnum} from "../enums/connection-origin-enum";
import {SessionModel} from "../models/session/session-model";
import {DoneResponseModel} from "../models/done-response-model";
import {DomainCollectionModel} from "../models/session/domain-collection-model";
import {StringUtils} from "../helpers/string.utils";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  getSession(): Observable<SessionModel> {
    const url = environment.urlGetSession;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      }
    }, options).pipe(map(data => new SessionModel().deserialize(data['session'])),
      catchError(() => throwError('Connection not possible'))
    );
  }

  revokeSession(sessionIdentifier): Observable<DoneResponseModel> {
    const url = environment.urlRevokeSession;
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        connectionOrigin: ConnectionOriginEnum.CONNECTION_ORIGIN_CITYMAP,
        sessionIdentifier: sessionIdentifier
      }
    }, options).pipe(map(data => new DoneResponseModel().deserialize(data)),
      catchError(() => throwError('Closing session not possible'))
    );
  }

  getDomains(): Observable<DomainCollectionModel> {
    const url = environment.urlGetDomains;
    return this.httpService.get(url).pipe(map(data => new DomainCollectionModel().deserialize(data)),
      catchError(() => throwError('Domain list not accessible'))
    );
  }

  getSessionByToken(token: string) {
    if (StringUtils.isNotEmpty(token)) {
      this.session.token = token;

      this.getSession().subscribe(sessionModel => {
        this.session.loadSession(sessionModel);
      });
    } else {
      console.log('User token missing');
    }
  }

  getSessionByLogin(domain: string, username: string, password: string) {
    this.session.token = btoa(domain + '@' + username + ':' + password);
    this.getSession().subscribe(sessionModel => {
      this.session.loadSession(sessionModel);
    });
  }
}
