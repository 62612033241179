import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {StatusEnum} from "../enums/status-enum.enum";
import {StatusListModel} from "../models/masterData/status-list-model";
import {ListCollectionModel} from "../models/masterData/list-collection-model";
import {ElementClassCollectionModel} from "../models/masterData/element-class-collection-model";

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  getStatusList(sessionIdentifier): Observable<StatusListModel> {
    const url = environment.urlGetStatusList;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
        sessionIdentifier: sessionIdentifier
      },
      statusGroup: StatusEnum.STATUS_GROUP_PROCESSING
    }, options).pipe(map(data => new StatusListModel().deserialize(data)),
      catchError(() => throwError('Status List not found'))
    );
  }

  getList(listCode): Observable<ListCollectionModel> {
    const url = environment.urlMasterdataGetLists;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ]
      },
      listCode: listCode
    }, options).pipe(map(data => new ListCollectionModel().deserialize(data)),
      catchError(() => throwError(listCode + ' : List not found'))
    );
  }

  getElementClassByGeometry(elementType: string, category: string, classGeoType?: string): Observable<ElementClassCollectionModel> {
    const url = environment.urlGetElementClass;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      
    const options = {headers: headers};

    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ],
      },
      elementType: elementType,
      classCategory: category,
      classGeoType: classGeoType
    }, options).pipe(map(data => {
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('Element class list not found');
      })
    );
  }

  getElementClassByClassName(elementClass: string): Observable<ElementClassCollectionModel> {
    const url = environment.urlGetElementClass;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      
    const options = {headers: headers};

    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL,
          ResponseLevelEnum.PAGING
        ],
      },
      elementClass: elementClass
    }, options).pipe(map(data => {
        return new ElementClassCollectionModel().deserialize(data);
      }),
      catchError(error => {
        return throwError('Element class not found');
      })
    );
  }
}
