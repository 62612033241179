import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {catchError, map} from 'rxjs/operators';
import {Session} from "../globals/session";
import {PricingGridCollectionModel} from "../models/pricing/pricing-grid-collection-model";
import {PricingGridModel} from "../models/pricing/pricing-grid-model";
import {FeatureModel} from "../models/geojson/feature-model";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";
import {FeatureObjectElementModel} from "../models/geojson/feature-object-element-model";
import {ProductModel} from "../models/pricing/product-model";
import {ElementTypesEnum} from "../enums/element-types-enum.enum";
import {ObjectUtils} from "../helpers/object.utils";
import {StringUtils} from "../helpers/string.utils";

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor(private session: Session, private httpService: HttpClient) {
  }

  addPriceProductByFeatureModel(feature: FeatureModel, allProductModels: ProductModel[]): FeatureModel {
    if (feature !== undefined && feature.properties !== undefined
      && feature.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ELEMENT) {
      let featureProperties: FeatureObjectElementModel = <FeatureObjectElementModel>feature.properties;

      // Filter by ElementType & Class
      let productModels: ProductModel[] = allProductModels.filter(item => {
        return (item.elementType === featureProperties.elementType
          && StringUtils.toUpperCase(item.elementClass) === StringUtils.toUpperCase(featureProperties.elementClass));
      });

      // More filters for Ground
      if (productModels !== undefined
        && featureProperties.elementProperties !== undefined
        && featureProperties.elementProperties["qualification"] !== undefined) {

        if (featureProperties.elementType == ElementTypesEnum.ELEMENT_TYPE_GROUND
          || featureProperties.elementType == ElementTypesEnum.ELEMENT_TYPE_LANE) {

          if (featureProperties.elementIdentifier === '50d0e121-cb09-45db-87b9-b4dcce713532') {
            console.log(featureProperties);
            console.log(productModels);
          }
          // Painting
          productModels = productModels.filter(item => {

            return (ObjectUtils.isEmpty(item.productType) || ObjectUtils.isEmpty(item.productType.valueCode) ||
              StringUtils.toUpperCase(item.productType.valueCode) === StringUtils.toUpperCase(featureProperties.elementProperties["qualification"]["painting_type"]));
          });

          if (featureProperties.elementIdentifier === '50d0e121-cb09-45db-87b9-b4dcce713532') {
            console.log(productModels);
          }
          // Color
          productModels = productModels.filter(item => {

            return (ObjectUtils.isEmpty(item.productColor) || ObjectUtils.isEmpty(item.productColor.valueCode) ||
              StringUtils.toUpperCase(item.productColor.valueCode) === StringUtils.toUpperCase(featureProperties.elementProperties["qualification"]["colour"]));
          });

          if (featureProperties.elementIdentifier === '50d0e121-cb09-45db-87b9-b4dcce713532') {
            console.log(productModels);
          }
          // Size
          if (productModels !== undefined) {
            productModels = productModels.filter(item => {


              return (ObjectUtils.isEmpty(item.productSize) || ObjectUtils.isEmpty(item.productSize.valueCode) ||
                StringUtils.toUpperCase((item.productSize.valueCode).toString()) === StringUtils.toUpperCase((featureProperties.elementProperties["qualification"]["size"]).toString()));
            });
          }

          if (featureProperties.elementIdentifier === '50d0e121-cb09-45db-87b9-b4dcce713532') {
            console.log(productModels);
          }
          featureProperties.elementPricingValue = 0;

          // Price calculation
          if (productModels !== undefined && productModels.length > 0) {

            let product: ProductModel = productModels[0];
            featureProperties.elementPricingProduct = product;

            if (ObjectUtils.isNotEmpty(product.priceMethod)
              && featureProperties.elementProperties["metrics"] !== undefined) {

              if (featureProperties.elementName === 'BUS_STATION') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["painted_length"] * product.priceValue;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (featureProperties.elementName === 'PARKING') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["unit_number"] * product.priceValue * 5;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (featureProperties.elementName === 'PARKING_NO') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["unit_number"] * product.priceValue * 6;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (featureProperties.elementName === 'PARKING_DROIT') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["unit_number"] * product.priceValue * 5;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (featureProperties.elementName === 'PARKING_EPI') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["unit_number"] * product.priceValue * 7;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (product.priceMethod.valueCode === 'METRE_LINEAIRE') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["linear_length"] * product.priceValue;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (product.priceMethod.valueCode === 'METRE_CARRE') {
                if (featureProperties.elementProperties["metrics"]["painted_surface"] === undefined) {
                  featureProperties.elementProperties["metrics"]["painted_surface"] = featureProperties.elementProperties["metrics"]["fill_factor"]
                    * featureProperties.elementProperties["metrics"]["unit_number"];
                }
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["painted_surface"] * product.priceValue;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              } else if (product.priceMethod.valueCode === 'UNITE') {
                featureProperties.elementPricingValue = featureProperties.elementProperties["metrics"]["unit_number"] * product.priceValue;
                featureProperties.elementPricingMethod = product.priceMethod.valueCode;
              }
            }
          }
        }
      }
    }
    return feature;
  }

  getPricingGrids(gridCode: string = ''): Observable<PricingGridCollectionModel> {
    const url = environment.urlPricingGetPricingGrids;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.MINIMIZE
        ],
      },
      gridCode,
    }, options).pipe(map(data => new PricingGridCollectionModel().deserialize(data)),
      catchError((err) => throwError(err))
    );
  }

  getPricingGridDetails(gridCode: string): Observable<PricingGridModel> {
    const url = environment.urlPricingGetPricingGridDetails;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)


    const options = {headers};
    return this.httpService.post(url, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ],
      },
      gridCode,
    }, options).pipe(map(data => new PricingGridModel().deserialize(data['pricingGrid'])),
      catchError((err) => throwError(err))
    );
  }


}
