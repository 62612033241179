<p-treeTable [value]="_treeNode" [style]="{'margin-top':'20px'}" >
  <ng-template pTemplate="header">
    <tr>
      <th align="left" i18n>Class</th>
      <th width="80px" i18n>Quality</th>
      <th width="80px" i18n>Count</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr>
      <td>
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        {{rowData.elementGroup}}
      </td>
      <td align="right">
        {{rowData.elementQuality | number:'1.0-0'}} %
      </td>
      <td align="right">
        {{rowData.elementCount | number:'1.0-0'}}
      </td>
    </tr>
  </ng-template>
</p-treeTable>
