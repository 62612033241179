import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.css']
})
export class MapPageComponent implements OnInit {

  _showDialogGround:boolean = false;

  constructor() { }

  ngOnInit() {

  }

  showDialogGround(show: boolean) {
    this._showDialogGround = show;
  }
}
