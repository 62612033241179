import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpHeadersEnum} from '../enums/http-headers-enum.enum';
import {ContentTypesEnum} from '../enums/content-types-enum.enum';
import {AuthorizationTypesEnum} from '../enums/authorization-types-enum.enum';
import {ResponseLevelEnum} from '../enums/response-level-enum.enum';
import {FeatureCollectionModel} from '../models/geojson/feature-collection-model';
import {catchError, map} from 'rxjs/operators';
import {GeometryModel} from '../models/geojson/geometry-model';
import {Session} from "../globals/session";
import {RequestHelper} from "../helpers/request.helper";

@Injectable({
  providedIn: 'root'
})
export class GeojsonService {

  constructor(private session: Session, private requestHelper: RequestHelper, private httpService: HttpClient) {}

   getObjectElementCollection(elementType: string, elementName: string = undefined, elementClass: string = undefined, elementClassList: string[] = undefined, elementIdentifier: string = undefined, elementDateBegin: string = undefined, elementDateEnd: string = undefined, geometry: GeometryModel = undefined,  distance: number = 15, onlyRelevant:boolean = undefined, onlyConfirmed:boolean = undefined, onlyPrimary: boolean = undefined, elementQualityMin : number = undefined, elementQualityMax : number = undefined): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetObjectElementCollection;

    let headers = this.requestHelper.createHttpHeaders();
    let options = {headers};

    return this.httpService.post(url, {
      header: this.requestHelper.createHeader([ResponseLevelEnum.ALL], -1,-1),
      elementName: elementName,
      elementType: elementType,
      elementClass: elementClass,
      elementClassList: elementClassList,
      elementIdentifier: elementIdentifier,
      elementDateBegin: elementDateBegin,
      elementDateEnd: elementDateEnd,
      elementQualityMin : elementQualityMin,
      elementQualityMax : elementQualityMax,
      distance: distance,
      geometry: geometry,
      isRelevant: (onlyRelevant === true ? true : undefined),
      isConfirmed: (onlyConfirmed === true ? true : undefined),
      isPrimary: (onlyPrimary === true ? true : undefined)
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('Collection not found'))
    );
  }

  getSnapshotCollection(snapshotIdentifier: string): Observable<FeatureCollectionModel> {
    const url = environment.urlGeoJsonGetSnapshotCollection + "?snapshotIdentifier=" + snapshotIdentifier;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('SnapshotCollection not found'))
    );
  }

  findNearestViewCollection(long:number, lat:number, mode:string, distance:number, excludeView:string): Observable<FeatureCollectionModel> {
    const url = environment.urlFindNearestViewCollection +
      mode + '&distance=' + distance + '&long=' + long + '&lat=' + lat + '&excludeViewIdentifier=' + excludeView;

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers};
    return this.httpService.get(url , options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('View not found'))
    );
  }

  getPreviousAndNextViewCollection(viewType:string, snapshotIdentifier:string, viewOrder:number): Observable<FeatureCollectionModel> {
    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      

    const options = {headers: headers};
    return this.httpService.post(environment.urlGetPreviousAndNextViewCollection, {
      header: {
        responseLevel: [
          ResponseLevelEnum.ALL
        ]
      },
      viewType: viewType,
      snapshotIdentifier: snapshotIdentifier,
      viewOrder: viewOrder
    }, options).pipe(map(data => new FeatureCollectionModel().deserialize(data)),
      catchError(() => throwError('View not found'))
    );
  }
}
