import { Pipe, PipeTransform } from '@angular/core';
import {StringUtils} from "../../helpers/string.utils";

@Pipe({
  name: 'elementGroupGround'
})
export class ElementGroupGroundPipe implements PipeTransform {

  transform(classGroup: string = ""): string {
    let elementGroundNameTransfrom = '';

    classGroup = classGroup.toUpperCase();

    if(classGroup === 'ARROW'){
      elementGroundNameTransfrom = 'Flèches'
    }
    if(classGroup === 'LINE'){
      elementGroundNameTransfrom = 'Ligne'
    }
    if(classGroup === 'LANE'){
      elementGroundNameTransfrom = 'Lignes'
    }
    if(classGroup === 'OTHER'){
      elementGroundNameTransfrom = 'Autres'
    }
    if(classGroup === 'PARKING'){
      elementGroundNameTransfrom = 'Parking'
    }
    if(classGroup === 'SIGLE'){
      elementGroundNameTransfrom = 'Sigle'
    }
    if(classGroup === 'ZONE'){
      elementGroundNameTransfrom = 'Zone'
    }
    if(classGroup === 'PICTO'){
      elementGroundNameTransfrom = 'Pictogrammes'
    }
    if(classGroup === 'TEXT'){
      elementGroundNameTransfrom = 'Textes'
    }
    if(classGroup === 'TS'){
      elementGroundNameTransfrom = 'Travaux Spéciaux'
    }

    if (StringUtils.isEmpty(elementGroundNameTransfrom)) {
      elementGroundNameTransfrom = classGroup;
    }

    return elementGroundNameTransfrom;
  }

}
