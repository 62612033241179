import {IFeatureProperties} from './ifeature-properties';
import {GeometryModel} from './geometry-model';
import {ISerializable} from '../iserializable';
import {FeatureObjectElementModel} from './feature-object-element-model';
import {FeatureTypesEnum} from '../../enums/feature-types-enum.enum';
import {FeatureObjectViewModel} from "./feature-object-view-model";
import {FeatureObjectSnapshotModel} from "./feature-object-snapshot-model";
import {FeatureObjectPoiModel} from "../modeling/feature-object-poi-model";
import {FeatureObjectWayModel} from "../modeling/feature-object-way-model";
import {FeatureObjectZoneModel} from "../modeling/feature-object-zone-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../../enums/geometry-type-enum.enum";

export class FeatureModel implements ISerializable  {

  public type: string = 'Feature';

  public geometry: GeometryModel;

  public properties: IFeatureProperties;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.geometry = new GeometryModel().deserialize(input.geometry);

    if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ELEMENT){
      this.properties = new FeatureObjectElementModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_VIEW){
      this.properties = new FeatureObjectViewModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_SNAPSHOT){
      this.properties = new FeatureObjectSnapshotModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_POI){
      this.properties = new FeatureObjectPoiModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_WAY){
      this.properties = new FeatureObjectWayModel().deserialize(input.properties);

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ZONE){
      this.properties = new FeatureObjectZoneModel().deserialize(input.properties);

    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toString(): string {
    let value: string;

    if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ELEMENT) {
      value = this.properties["elementName"];

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_WAY) {
      value = this.properties["wayDisplayName"];

    } else if(this.properties.featureType === FeatureTypesEnum.FEATURE_TYPE_ZONE) {
      value = this.properties["zoneDisplayName"];

    }

    return value;
  }

  toTurf(): any {
    return turf.helpers.feature(this.geometry.toTurf(), this.properties.toJson());
  }

  toExcel(): any {
    let item: any = {};

    if (this.properties !== undefined) {
      item = this.properties.toExcel();
    }

    if (this.geometry !== undefined
          && this.geometry.type === GeometryTypeEnum.POINT) {
      item.geoLongitude = this.geometry.coordinates[0];
      item.geoLatitude = this.geometry.coordinates[1];
      item.geoAltitude = this.geometry.coordinates[2];

    } else if (this.geometry !== undefined
      && this.geometry.type === GeometryTypeEnum.LINE_STRING) {
      item.geoLongitude = this.geometry.coordinates[0][0];
      item.geoLatitude = this.geometry.coordinates[0][1];
      item.geoAltitude = this.geometry.coordinates[0][2];

      item.geoLongitudeEnd = this.geometry.coordinates[this.geometry.coordinates.length -1][0];
      item.geoLatitudeEnd = this.geometry.coordinates[this.geometry.coordinates.length -1][1];
      item.geoAltitudeEnd = this.geometry.coordinates[this.geometry.coordinates.length -1][2];

    } else if (this.geometry !== undefined) {

      let value = turf.centroid(this.geometry.toTurf());

      let centroid:GeometryModel = new GeometryModel();
      centroid.type = value.geometry.type;
      centroid.coordinates = value.geometry.coordinates;

      item.geoLongitude = centroid.coordinates[0];
      item.geoLatitude = centroid.coordinates[1];
      item.geoAltitude = centroid.coordinates[2];
    }

    return item;
  }
}
