const urlApi = 'https://api-dev.citymagine.biz';

export const environment = {
  auth : {
    domain: 'dev-citymagine.eu.auth0.com',
    clientId: '4lYvkmm4Foe8JCcQEGKuhXDU1GE4BKeZ',
    audience: 'ApiMain',
    apiUri: urlApi+'/*'
  },
  production: false,

  urlMedia: 'https://media-dev.citymagine.biz/',
  urlStorage: 'https://storage-dev.citymagine.biz/',
  urlCityMap: 'https://citymap-dev.citymagine.biz/',

// const urlApi = 'https://api.citymagine.com';
//
// export const environment = {
//   production: true,
//
//   urlMedia: 'https://media-prod.citymagine.com/',
//   urlStorage: 'https://storage.citymagine.com/',
//   urlCityMap: 'https://citymap3.citymagine.com/',

// TaskService
  urlAckProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/ackProcessTask',
  urlCloseProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/closeProcessTask',
  urlCreateProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/createProcessTask',
  urlGetProcessTasks: urlApi + '/citymagine-api-1.0/rest/tasks/getProcessTasks',

  // ModelingService *
  urlGeoJsonGetModelPoiCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelPoiCollection',
  urlGetModelingCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelingCollection',
  urlGetModelWayCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelWayCollection',
  urlGetModelZoneCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelZoneCollection',

  // GeoJsonService *
  urlGeoJsonGetObjectElementCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getObjectElementCollection',
  urlGeoJsonGetSnapshotCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getSnapshotCollection?',
  urlFindNearestViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/findNearestViewCollection?viewType=',
  urlGetPreviousAndNextViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getPreviousAndNextViewCollection',

  // SessionService *
  urlGetSession: urlApi + '/citymagine-api-1.0/rest/session/getSession',
  urlGetDomains: urlApi + '/citymagine-api-1.0/rest/session/getDomains',
  urlRevokeSession: urlApi + '/citymagine-api-1.0/rest/session/revokeSession',

  // MasterDataService
  urlGetStatusList: urlApi + '/citymagine-api-1.0/rest/masterdata/getStatus',
  urlMasterdataGetLists: urlApi + '/citymagine-api-1.0/rest/masterdata/getLists',
  urlGetElementClass: urlApi + '/citymagine-api-1.0/rest/masterdata/getElementClass',


  // ProcessingService
  urlProcessingGetSnapshots: urlApi + '/citymagine-api-1.0/rest/processing/getSnapshots',
  urlProcessingUpdateSnapshotStatus: urlApi + '/citymagine-api-1.0/rest/processing/updateSnapshotStatus',
  urlGetAnalysisClass: urlApi + '/citymagine-api-1.0/rest/processing/getAnalysisClass',

  // SecurityService
  urlSecurityResetPassword: urlApi + '/citymagine-api-1.0/rest/security/resetPassword',
  urlSecurityUpdateUser: urlApi + '/citymagine-api-1.0/rest/security/updateUser',

  // PricingService
  urlPricingCreatePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/createPricingGrid',
  urlPricingGetPricingGrids: urlApi + '/citymagine-api-1.0/rest/pricing/getPricingGrids',
  urlPricingUpdatePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/updatePricingGrid',
  urlPricingDeletePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/deletePricingGrid',
  urlPricingGetPricingGridDetails: urlApi + '/citymagine-api-1.0/rest/pricing/getPricingGridDetails',
  urlPricingCreatePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/createPricingGridProduct',
  urlPricingUpdatePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/updatePricingGridProduct',
  urlPricingDeletePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/deletePricingGridProduct',

  urlGetDefaultDomain: urlApi + '/citymagine-api-1.0/rest/user/defaultDomain',
  urlSetDefaultDomain: urlApi + `/citymagine-api-1.0/rest/user/profile`,
  urlSetCurrentDomain: (domain)=> urlApi + `/citymagine-api-1.0/rest/user/domain/${domain}`,

};
// const urlApi = 'https://api.citymagine.com';
//
// export const environment = {
//   production: true,
//
//   urlMedia: 'https://media-prod.citymagine.com/',
//   urlStorage: 'https://storage.citymagine.com/',
//   urlCityMap: 'https://citymap3.citymagine.com/',
//
// // TaskService
//   urlAckProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/ackProcessTask',
//   urlCloseProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/closeProcessTask',
//   urlCreateProcessTask: urlApi + '/citymagine-api-1.0/rest/tasks/createProcessTask',
//   urlGetProcessTasks: urlApi + '/citymagine-api-1.0/rest/tasks/getProcessTasks',
//
//   // ModelingService *
//   urlGeoJsonGetModelPoiCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelPoiCollection',
//   urlGetModelingCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelingCollection',
//   urlGetModelWayCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelWayCollection',
//   urlGetModelZoneCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getModelZoneCollection',
//
//   // GeoJsonService *
//   urlGeoJsonGetObjectElementCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getObjectElementCollection',
//   urlGeoJsonGetSnapshotCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getSnapshotCollection?',
//   urlFindNearestViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/findNearestViewCollection?viewType=',
//   urlGetPreviousAndNextViewCollection: urlApi + '/citymagine-api-1.0/rest/geojson/getPreviousAndNextViewCollection',
//
//   // SessionService *
//   urlGetSession: urlApi + '/citymagine-api-1.0/rest/session/getSession',
//   urlGetDomains: urlApi + '/citymagine-api-1.0/rest/session/getDomains',
//   urlRevokeSession: urlApi + '/citymagine-api-1.0/rest/session/revokeSession',
//
//   // MasterDataService
//   urlGetStatusList: urlApi + '/citymagine-api-1.0/rest/masterdata/getStatus',
//   urlMasterdataGetLists: urlApi + '/citymagine-api-1.0/rest/masterdata/getLists',
//   urlGetElementClass: urlApi + '/citymagine-api-1.0/rest/masterdata/getElementClass',
//
//   // ProcessingService
//   urlProcessingGetSnapshots: urlApi + '/citymagine-api-1.0/rest/processing/getSnapshots',
//   urlProcessingUpdateSnapshotStatus: urlApi + '/citymagine-api-1.0/rest/processing/updateSnapshotStatus',
//   urlGetAnalysisClass: urlApi + '/citymagine-api-1.0/rest/processing/getAnalysisClass',
//
//   // SecurityService
//   urlSecurityResetPassword: urlApi + '/citymagine-api-1.0/rest/security/resetPassword',
//   urlSecurityUpdateUser: urlApi + '/citymagine-api-1.0/rest/security/updateUser',
//
//   // PricingService
//   urlPricingCreatePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/createPricingGrid',
//   urlPricingGetPricingGrids: urlApi + '/citymagine-api-1.0/rest/pricing/getPricingGrids',
//   urlPricingUpdatePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/updatePricingGrid',
//   urlPricingDeletePricingGrid: urlApi + '/citymagine-api-1.0/rest/pricing/deletePricingGrid',
//   urlPricingGetPricingGridDetails: urlApi + '/citymagine-api-1.0/rest/pricing/getPricingGridDetails',
//   urlPricingCreatePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/createPricingGridProduct',
//   urlPricingUpdatePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/updatePricingGridProduct',
//   urlPricingDeletePricingGridProduct: urlApi + '/citymagine-api-1.0/rest/pricing/deletePricingGridProduct',
//
// };
