

import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Session } from '../session';

/** Inject With Credentials into the request */
@Injectable()
export class HttpCredentialsRequestInterceptor implements HttpInterceptor {

  constructor(private session:Session){

  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {    
      if(req.url.match(environment.auth.apiUri).length){
        req = req.clone({
          withCredentials: true,
          setHeaders: {
              Authorization: `Bearer ${this.session.token}`
          }
        });
      }
      return next.handle(req);
  }
}