<div *ngIf="loading" class="loaderContainer">
  <p-progressSpinner></p-progressSpinner>
</div>
<div id="header" [ngClass]="{ 'phoneHeader': isMatched, 'computerHeader': !isMatched}">
  <ul [ngClass]="{'phoneDisplay': isMatched, 'computerDisplay': !isMatched}">
    <li class="title" routerLink="map">
      <img src="assets/logo-citymagine.png"/>
<!--      <p><span class="city">City</span><span style="opacity:0.7;">Road</span></p>-->
    </li>
    <li class="user" *ngIf="!isMatched">
      <p-menubar [model]="menuItems"></p-menubar>
    </li>
    <li class="listPrice" *ngIf="!isMatched">
      <p-dropdown  [options]="grid1" placeholder="Liste des grilles" [(ngModel)]="selectedGrid" (onChange)="onChangeCode(selectedGrid.code)"></p-dropdown>
    </li>
    <li class="user">
      <p-slideMenu #menu [model]="userItems" [popup]="true"></p-slideMenu>
      <button #btn type="button" pButton icon="pi pi-user" class="ui-button-secondary" (click)="menu.toggle($event)"></button>
    </li>
  </ul>
  <p-dropdown *ngIf="isMatched" [options]="grid1" placeholder="Liste des grilles" [(ngModel)]="selectedGrid" (onChange)="onChangeCode(selectedGrid.code)"
              class="dropdownMatch"></p-dropdown>
</div>
<p-sidebar [(visible)]="visibleSidebar1"  [style]="{'width':'26em','cursor':'pointer'}" position="left" [baseZIndex]="10000" >
  <div class="titleMenu">
    <h1>City<span class="titleSpan">Road</span></h1>
  </div>
  <ul class="subMenu">
    <li class="listMenu itemChecked"   routerLink="/home/map" >
      <i class="fa fa-globe"></i>
      <span i18n>CityMap</span>
    </li>
    <li class="listMenu" >
      <i class="pi pi-list"></i><span></span>
      <p-dropdown id="dropdownGrid"  [options]="grid1" placeholder="Liste des grilles" [(ngModel)]="selectedGrid" (onChange)="onChangeCode(selectedGrid.code)"></p-dropdown>
    </li>
  </ul>
</p-sidebar>

