<div class="p-grid">
  <div class="p-col-12 header">
    <app-menu (changeGrid)="changeGridCode($event)"></app-menu>
  </div>
</div>
<div class="ui-g ui-g-nopad">
  <div class="ui-g-12 layout-content">
    <div class="mainContainer" [ngClass]="{ 'mainContainerPhone': isMatched, 'mainContainerComputer': !isMatched}">
      <router-outlet></router-outlet>
      <app-spinner id="spinnerComponent" #spinnerComponent></app-spinner>
    </div>
  </div>
</div>
