<app-city-map #cityMap></app-city-map>
<app-summary [showSign]="cityMap.showSignSummary" [showGround]="cityMap.showGroundSummary" [showLane]="cityMap.showLaneSummary"
             [showReflecto]="cityMap.showReflectoSummary" [featuresReflecto]="cityMap.featuresReflecto"
             [distanceRedReflecto]=" cityMap.redQualityDistance" [distanceFullReflecto] = "cityMap.fullDistance"
             [featuresSign]="cityMap.featuresSign" [featuresGround]="cityMap.featuresGround" [featuresLane]="cityMap.featuresLane"

></app-summary>
<app-dialog-ground *ngIf="cityMap.showLaneList" [(visible)]="cityMap.showLaneList" [features]="cityMap.featuresLane"></app-dialog-ground>
<app-dialog-ground *ngIf="cityMap.showGroundList" [(visible)]="cityMap.showGroundList" [features]="cityMap.featuresGround"></app-dialog-ground>
<app-dialog-sign *ngIf="cityMap.showSignList" [(visible)]="cityMap.showSignList" [features]="cityMap.featuresSign"></app-dialog-sign>
<app-dialog-reflecto *ngIf="cityMap.showReflectoList" [(visible)]="cityMap.showReflectoList" [features]="cityMap.featuresReflecto"></app-dialog-reflecto>
