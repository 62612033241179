import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name:
    'elementQualityPipe'
})

export class ElementQualityPipe implements PipeTransform {

  transform(elementQuality: number): string {
    let elementQualityTransform = '';

    if (elementQuality === 100) {
      elementQualityTransform = 'Très bonne';
    } else if (elementQuality > 50) {
      elementQualityTransform = 'Bonne';
    } else if (elementQuality > 25) {
      elementQualityTransform = 'Moyenne';
    } else {
      elementQualityTransform = 'Mauvaise'
    }
    return elementQualityTransform;
  }
}
