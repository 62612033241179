import {EventEmitter, Injectable, Output} from "@angular/core";
import { Router} from '@angular/router';
import {GeometryModel} from "../models/geojson/geometry-model";
import {GeometryHelper} from "../helpers/geometry.helper";
import {SessionModel} from "../models/session/session-model";
import { DomainCollectionModel } from "../models/session/domain-collection-model";


@Injectable({
  providedIn: 'root'
})
export class Session {

  @Output() onChangeWorkspace: EventEmitter<GeometryModel> = new EventEmitter();

  isLogged: boolean = false;
  isLogging = false;
  token: string;
  currentSession: SessionModel;
  sessionIdentifier: string;
  sessionLocale: string;
  sessionProperties: any;
  domains: DomainCollectionModel;
  currentDomain: number;
  _workspaceGeo: GeometryModel;

  constructor(private router: Router){}
  setWorkspace(geometry: GeometryModel) {
    if (GeometryHelper.isValidGeometry(geometry)) {
      this._workspaceGeo = geometry;
    } else {
      this._workspaceGeo = undefined;
    }
    this.onChangeWorkspace.emit(geometry);
  }

  getWorkspace(): GeometryModel {
    return this._workspaceGeo;
  }

  loadSession(sessionModel: SessionModel) {
    if (sessionModel != undefined) {
      this.sessionIdentifier = sessionModel.sessionIdentifier;
      this.isLogged = true;
      this.isLogging = false;
      this.currentSession = sessionModel;
      this.sessionLocale = sessionModel.user.userLocale;
      this.sessionProperties = sessionModel.user.userProperties;
      this.setWorkspace(sessionModel.user.userGeometry);
      window.sessionStorage.setItem('sessionIdentifier', this.sessionIdentifier);
      window.sessionStorage.setItem('token', this.token);
      window.sessionStorage.setItem('currentSession', JSON.stringify(this.currentSession));
      this.router.navigate(['home/map/']);

    } else {
      this.sessionIdentifier = undefined;
      this.isLogged = false;
      this.isLogging = false;
      this.currentSession = undefined;
      this.sessionLocale = "en_GB";
      this.sessionProperties = undefined;

      this.setWorkspace(undefined);
    }
  }

  hasRole(role:string): boolean {
    return this.currentSession.user.userRoles.includes(role);
  }
}
