export enum ResponseLevelEnum {
  MINIMIZE = 'MINIMIZE',
  ALL = 'ALL',
  NOTHING = 'NOTHING',
  PAGING = 'PAGING',
  USER_DETAILS = 'USER_DETAILS',
  SNAPSHOT_GEO ='SNAPSHOT_GEO',
  SNAPSHOT_EVENTS ='SNAPSHOT_EVENTS',
  SNAPSHOT_DETAILS ='SNAPSHOT_DETAILS',
  STATUS_DETAILS ='STATUS_DETAILS',
  USER_GEO = 'USER_GEO'
}
