import { Pipe, PipeTransform } from '@angular/core';
import {StringUtils} from "../../helpers/string.utils";

@Pipe({
  name: 'elementNameGround'
})
export class ElementNameGroundPipe implements PipeTransform {

  transform(elementName: string = ""): string {
    let elementGroundNameTransfrom = '';

    elementName = elementName.toUpperCase();

    if(elementName === 'AXE'){
      elementGroundNameTransfrom = 'Flèche - simple axe'
    }
    if(elementName === 'AXE_LEFT'){
      elementGroundNameTransfrom = 'Flèche axe et gauche'
    }
    if(elementName === 'AXE_RIGHT'){
      elementGroundNameTransfrom = 'Flèche axe et droite'
    }
    if(elementName === 'DOUBLE_LEFT'){
      elementGroundNameTransfrom = 'Flèche - double gauche'
    }
    if(elementName === 'DOUBLE_RIGHT'){
      elementGroundNameTransfrom = 'Flèche - double droite'
    }
    if(elementName === 'TRIPLE'){
      elementGroundNameTransfrom = 'Flèche - gauche-axe-droite'
    }
    if(elementName === 'DRAWDOWN'){
      elementGroundNameTransfrom = 'Flèche rabattement'
    }
    if(elementName === 'DRAWDOWN_LEFT'){
      elementGroundNameTransfrom = 'Flèche rabattement gauche'
    }
    if(elementName === 'LEFT'){
      elementGroundNameTransfrom = 'Flèche tourner à gauche'
    }
    if(elementName === 'LEFT_RIGHT'){
      elementGroundNameTransfrom = 'Flèche - gauche / droite'
    }
    if(elementName === 'RIGHT'){
      elementGroundNameTransfrom = 'Flèche tourner à droite'
    }
    if(elementName === 'CDP'){
      elementGroundNameTransfrom = 'Cedez le passage'
    }
    if(elementName === 'DOTTED'){
      elementGroundNameTransfrom = 'Ligne discontinue'
    }
    if(elementName === 'FIRM'){
      elementGroundNameTransfrom = 'Ligne continue'
    }
    if(elementName === 'MIXED'){
      elementGroundNameTransfrom = 'Ligne mixte'
    }
    if(elementName === 'PARKING'){
      elementGroundNameTransfrom = 'Zone de stationnement'
    }
    if(elementName === 'PARKING_BARRE'){
      elementGroundNameTransfrom = 'Zone de stationnement interdit'
    }
    if(elementName === 'BUS'){
      elementGroundNameTransfrom = 'Station de bus'
    }
    if(elementName === 'PARKING_EPI'){
      elementGroundNameTransfrom = 'Zone de stationnement en épit'
    }
    if(elementName === 'PARKING_SLOT'){
      elementGroundNameTransfrom = 'Zone de stationnement en slot'
    }
    if(elementName === 'PEDCROSSING') {
      elementGroundNameTransfrom = 'Passage piétons'
    }
    if(elementName === 'SPEEDBUMP'){
      elementGroundNameTransfrom = 'Ralentisseurs'
    }
    if(elementName === 'STOP'){
      elementGroundNameTransfrom = 'Ligne stop'
    }
    if(elementName === 'ZEBRA'){
      elementGroundNameTransfrom = 'Zebra slash'
    }
    if(elementName === 'ZEBRA_CHEVRON'){
      elementGroundNameTransfrom = 'Zebra chevron'
    }
    if(elementName === 'ZEBRA_DAMIER'){
      elementGroundNameTransfrom = 'Zebra en damier'
    }
    if(elementName === 'ZEBRA_PLEIN') {
      elementGroundNameTransfrom = 'Zebra plein'
    }
    if(elementName === 'PMR'){
      elementGroundNameTransfrom = 'Sigle PMR'
    }
    if(elementName === 'BIKE') {
      elementGroundNameTransfrom = 'Sigle Velo'
    }
    if(elementName === 'T1') {
      elementGroundNameTransfrom = 'Ligne discontinue T1'
    }
    if(elementName === 'T1') {
      elementGroundNameTransfrom = 'Ligne discontinue T1'
    }
    if(elementName === 'T1_PRIME') {
      elementGroundNameTransfrom = 'Ligne discontinue T\'1'
    }
    if(elementName === 'T2') {
      elementGroundNameTransfrom = 'Ligne discontinue T2'
    }
    if(elementName === 'T2_PRIME') {
      elementGroundNameTransfrom = 'Ligne discontinue T\'2'
    }
    if(elementName === 'T3') {
      elementGroundNameTransfrom = 'Ligne discontinue T3'
    }
    if(elementName === 'T3_PRIME') {
      elementGroundNameTransfrom = 'Ligne discontinue T\'3'
    }
    if(elementName === 'T4') {
      elementGroundNameTransfrom = 'Ligne discontinue T4'
    }
    if(elementName === 'MRE') {
      elementGroundNameTransfrom = 'Ligne discontinue MRE'
    }
    if(elementName === 'MT1') {
      elementGroundNameTransfrom = 'Ligne mixe T1'
    }
    if(elementName === 'MT1_PRIME') {
      elementGroundNameTransfrom = 'Ligne mixe T\'1'
    }
    if(elementName === 'MT2') {
      elementGroundNameTransfrom = 'Ligne mixe T2'
    }
    if(elementName === 'MT2_PRIME') {
      elementGroundNameTransfrom = 'Ligne mixe T\'2'
    }
    if(elementName === 'MT3') {
      elementGroundNameTransfrom = 'Ligne mixe T3'
    }
    if(elementName === 'MT3_PRIME') {
      elementGroundNameTransfrom = 'Ligne mixe T\'T3'
    }
    if(elementName === 'MT4') {
      elementGroundNameTransfrom = 'Ligne mixe T4'
    }
    if(elementName === 'DT1') {
      elementGroundNameTransfrom = 'Ligne double T1'
    }
    if(elementName === 'DT1_PRIME') {
      elementGroundNameTransfrom = 'Ligne double T\'1'
    }
    if(elementName === 'DT2') {
      elementGroundNameTransfrom = 'Ligne double T2'
    }
    if(elementName === 'DT2_PRIME') {
      elementGroundNameTransfrom = 'Ligne double T\'2'
    }
    if(elementName === 'DT3') {
      elementGroundNameTransfrom = 'Ligne double T3'
    }
    if(elementName === 'DT3_PRIME') {
      elementGroundNameTransfrom = 'Ligne double T\'3'
    }
    if(elementName === 'DT4') {
      elementGroundNameTransfrom = 'Ligne double T4'
    }
    if(elementName === 'DC') {
      elementGroundNameTransfrom = 'Ligne double continue'
    }
    if(elementName === 'useless') {
      elementGroundNameTransfrom = 'Indéterminé'
    }

    if(elementName === 'PEDESTRIAN') {
      elementGroundNameTransfrom = 'Sigle piéton'
    }
    if(elementName === 'BUS') {
      elementGroundNameTransfrom = 'Sigle BUS'
    }
    if(elementName === 'FAMILY') {
      elementGroundNameTransfrom = 'Sigle famille'
    }
    if(elementName === 'MOTHER_KID') {
      elementGroundNameTransfrom = 'Sigle mère'
    }
    if(elementName === 'LIVRAISON') {
      elementGroundNameTransfrom = 'Sigle livraison'
    }
    if(elementName === 'PAYANT') {
      elementGroundNameTransfrom = 'Sigle payant'
    }
    if(elementName === 'AUTOELEC') {
      elementGroundNameTransfrom = 'Sigle auto-élect'
    }
    if(elementName === 'LOGO30') {
      elementGroundNameTransfrom = 'Sigle logo 30'
    }
    if(elementName === 'LOGO30EXT') {
      elementGroundNameTransfrom = 'Sigle logo 30 étendu'
    }
    if(elementName === 'ZONE30') {
      elementGroundNameTransfrom = 'Sigle zone 30'
    }
    if(elementName === 'STOPTEXT') {
      elementGroundNameTransfrom = 'Sigle STOP'
    }
    if(elementName === 'PTRIANGLE') {
      elementGroundNameTransfrom = 'Sigle petit triangle'
    }
    if(elementName === 'GTRIANGLE') {
      elementGroundNameTransfrom = 'Sigle grand triangle'
    }

    if (StringUtils.isEmpty(elementGroundNameTransfrom)) {
      elementGroundNameTransfrom = elementName;
    }
    return elementGroundNameTransfrom;
  }

}
