import {Injectable} from '@angular/core';
import {FeatureModel} from "../models/geojson/feature-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class NumberUtils {

  constructor() {}

  static notNull(value: number, defaultValue: number = 0): number {
    if (value !== undefined){
      return value;
    } else {
      return defaultValue;
    }
  }
}
