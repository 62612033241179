<p-dialog [(visible)]="visible" [style]="{width: '60%',  left: '370px'}" [contentStyle]="{'max-height':'100%'}">
  <p-header>
    Liste d'éléments panneaux
  </p-header>
  <app-list-sign [features]="features"></app-list-sign>
  <p-footer>
    <p-splitButton label="Exporter Excel" icon="pi pi-save" (onClick)="exportExcel()" [model]="items" [style]="{marginRight: '10px'}"></p-splitButton>
    <p-button label="Fermer" (onClick)="show(false)"></p-button>
  </p-footer>
</p-dialog>
