<p-treeTable [value]="_treeNode" [style]="{'margin-top':'20px'}" class="treeNode">
  <ng-template pTemplate="header">
    <tr>
      <th align="left" width="210px" i18n>Element</th>
      <th width="80px" i18n>Quality</th>
      <th width="80px" i18n>Count</th>

      <th width="100px" i18n>Surface</th>
      <th width="100px" i18n>Painted surface</th>
      <th width="100px" i18n>Linear length</th>
      <th width="100px" i18n>Painted length</th>
      <th width="100px" i18n>Price estimation</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr>
      <td>
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        {{ rowData.elementName }}
      </td>
      <td align="right">
        {{rowData.elementQuality | number:'1.0-0'}} %
      </td>
      <td align="right">
        {{rowData.elementCount | number:'1.0-0'}}
      </td>

      <td align="right">
        {{rowData.surface | number:'1.1-1'}} m2
      </td>
      <td align="right">
        {{rowData.painted_surface | number:'1.1-1'}} m2
      </td>
      <td align="right">
        {{rowData.length | number:'1.1-1'}} m
      </td>
      <td align="right">
        {{rowData.painted_length | number:'1.1-1'}} m
      </td>
      <td align="right">
        {{rowData.pricing_value | number:'1.1-1'}} €
      </td>
    </tr>
  </ng-template>
</p-treeTable>

