import {Component, HostListener, OnInit} from '@angular/core';
import {Session} from "../../globals/session";
import {environment} from "../../../environments/environment";
import {EventCodeEnum} from "../../enums/event-code-enum.enum";
import {FeatureModel} from "../../models/geojson/feature-model";
import {ElementTypesEnum} from "../../enums/element-types-enum.enum";
import {CommunicationService} from "../../services/communication.service";
import {PricingService} from "../../services/pricing.service";
import {PricingGridModel} from "../../models/pricing/pricing-grid-model";
import {SessionService} from "../../services/session.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-city-map',
  templateUrl: './city-map.component.html',
  styleUrls: ['./city-map.component.css']
})
export class CityMapComponent implements OnInit {

  public url: string;

  public featuresGround:FeatureModel[] = [];
  public featuresLane:FeatureModel[] = [];
  public featuresSign:FeatureModel[] = [];
  public featuresReflecto:FeatureModel[] = [];

  public showGroundSummary:boolean = true;
  public showGroundList:boolean = false;

  public showLaneSummary:boolean = true;
  public showLaneList:boolean = false;

  public showSignSummary:boolean = true;
  public showSignList:boolean = false;

  public showReflectoSummary:boolean = true;
  public showReflectoList:boolean = false;

  private selectedPriceGridCode: string = undefined;
  private selectedPriceGrid: PricingGridModel = undefined;

  redQualityDistance: number;
  fullDistance: number;
  orangeQualityDistance : number;
  greenQualityDistance : number;

  constructor(private session: Session,
              private communication: CommunicationService,
              private pricingService: PricingService,
              private sessionService : SessionService,
              private router: Router) {
    const urlLocal = location.pathname.split('/')?.[1];
    const local = (urlLocal && urlLocal == 'en' ? 'en' : 'fr')
    this.url = environment.urlCityMap + local + "/?token=" + session.token;
  }

  ngOnInit() {
    this.communication.changeEmitted$.subscribe(data =>{
      this.changePriceGrid(data);
    });
  }

  private changePriceGrid(priceGridCode: string) {
    this.selectedPriceGridCode = priceGridCode;

    this.pricingService.getPricingGridDetails(priceGridCode).subscribe( (priceGrid: PricingGridModel) => {
      if (priceGrid) {
        this.selectedPriceGrid = priceGrid;
      } else {
        this.selectedPriceGrid = undefined;
      }

      this.calculateGroundPrice();
    });
  }

  private calculateGroundPrice() {
    if (this.selectedPriceGrid !== undefined) {
      if (this.featuresGround !== undefined) {
        let newFeaturesGround:FeatureModel[] = [];
        this.featuresGround.forEach(item => {
          this.pricingService.addPriceProductByFeatureModel(item, this.selectedPriceGrid.products);
          newFeaturesGround.push(item);
        });

        this.featuresGround = newFeaturesGround;
      }

      if (this.featuresLane !== undefined) {
        let newFeaturesLane:FeatureModel[] = [];
        this.featuresLane.forEach(item => {
          this.pricingService.addPriceProductByFeatureModel(item, this.selectedPriceGrid.products);
          newFeaturesLane.push(item);
        });

        this.featuresLane = newFeaturesLane;
      }
    }
  }

  @HostListener('window:message', ['$event'])
  onCityMapChange(event:MessageEvent) {

    if(event.data.eventCode === EventCodeEnum.WORKSPACE_CHANGE){
      this.session.setWorkspace(event.data.eventDetails);
    }

    if(event.data.eventCode === EventCodeEnum.LOGOUT){
      this.sessionService.revokeSession(this.session.sessionIdentifier).subscribe( response => {
        window.sessionStorage.clear();
        this.session.isLogged = false;
        this.session.isLogging = false;
        this.session.token = '';
        this.router.navigate(['/']);
      });
    }

    // GROUND //
    if (event.data.eventCode === EventCodeEnum.ELEMENTS_GROUND_LOADED) {
      this.featuresGround = [];

      if (event.data.eventDetails !== undefined) {
        event.data.eventDetails.forEach(item => {
          this.featuresGround.push(new FeatureModel().deserialize(item));
        });
      }

      this.calculateGroundPrice();
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_SUMMARY
          && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_GROUND) {
      if (this.featuresGround.length > 0) {
        this.showGroundSummary = !this.showGroundSummary;
      } else {
        this.showGroundSummary = false;
      }
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_LIST
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_GROUND) {
      if (this.featuresGround.length > 0) {
        this.showGroundList = !this.showGroundList;
      } else {
        this.showGroundList = false;
      }
    }


    // LANE //
    if (event.data.eventCode === EventCodeEnum.ELEMENTS_LANE_LOADED) {
      this.featuresLane = [];

      if (event.data.eventDetails !== undefined) {
        event.data.eventDetails.forEach(item => {
          this.featuresLane.push(new FeatureModel().deserialize(item));
        });
      }

      this.calculateGroundPrice();
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_SUMMARY
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_LANE) {
      if (this.featuresLane.length > 0) {
        this.showLaneSummary = !this.showLaneSummary;
      } else {
        this.showLaneSummary = false;
      }
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_LIST
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_LANE) {
      if (this.featuresLane.length > 0) {
        this.showLaneList = !this.showLaneList;
      } else {
        this.showLaneList = false;
      }
    }

    // SIGN //
    if (event.data.eventCode === EventCodeEnum.ELEMENTS_SIGN_LOADED) {
      this.featuresSign = [];

      if (event.data.eventDetails !== undefined) {
        event.data.eventDetails.forEach(item => {
          this.featuresSign.push(new FeatureModel().deserialize(item));
        });
      }
    }
    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_SUMMARY
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_SIGN) {
      if (this.featuresSign.length > 0) {
        this.showSignSummary = !this.showSignSummary;
      } else {
        this.showSignSummary = false;
      }
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_LIST
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_SIGN) {
      if (this.featuresSign.length > 0) {
        this.showSignList = !this.showSignList;
      } else {
        this.showSignList = false;
      }
    }

    //REFLECTO LOADED//
    if (event.data.eventCode === EventCodeEnum.ELEMENTS_QUALITY_LOADED) {
      this.featuresReflecto = [];

      if (event.data.eventDetails !== undefined) {
        event.data.eventDetails.forEach(item => {
          this.featuresReflecto.push(new FeatureModel().deserialize(item));
        });
        console.log(this.featuresReflecto);
      }
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_SUMMARY
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_REFLECTO) {
      console.log(event.data.eventDetails);
      console.log(this.featuresReflecto);

      if (this.featuresReflecto.length > 0) {
        this.showReflectoSummary = !this.showReflectoSummary;
      } else {
        this.showReflectoSummary = false;
      }
    }

    if (event.data.eventCode === EventCodeEnum.CMD_SHOW_LIST
      && event.data.eventDetails === ElementTypesEnum.ELEMENT_TYPE_REFLECTO) {
      console.log(this.featuresReflecto.length);
      if (this.featuresReflecto.length > 0) {
        this.showReflectoList = !this.showReflectoList;
      } else {
        this.showReflectoList = false;
      }
    }

    if (event.data.eventCode === EventCodeEnum.QUALITY_RED) {
      this.redQualityDistance = event.data.eventDetails;
      console.log(this.redQualityDistance);
    }
    if (event.data.eventCode === EventCodeEnum.QUALITY_FULL) {
      this.fullDistance = event.data.eventDetails;
      console.log(this.fullDistance);
    }

  }
}
