import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Session} from "../../globals/session";
import {FeatureModel} from "../../models/geojson/feature-model";
import {FeatureObjectElementModel} from "../../models/geojson/feature-object-element-model";
import * as turf from "@turf/turf";
import {ProductModel} from "../../models/pricing/product-model";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {ElementTypesEnum} from "../../enums/element-types-enum.enum";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @Input() showSign: boolean = false;
  @Input() showGround: boolean = false;
  @Input() showLane: boolean = false;
  @Input() showReflecto: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  isMatched: boolean = false;

  visible: boolean =false;
  countItemsSigle: number = 0;
  countItemsPolice: number = 0;
  countItemsDirection: number = 0;
  countItemsReflecto: number = 0;

  countQualityPolice: number = 0;
  countQualityDirection: number = 0;
  countQualitySigle: number = 0;
  countQualityReflecto: number = 0;

  groundPaintLength: number = 0;
  groundPaintSurface: number = 0;
  groundLinearLength: number = 0;
  groundEstimationPrice: number = 0;

  lanePaintLength: number = 0;
  lanePaintSurface: number = 0;
  laneLinearLength: number = 0;
  laneEstimationPrice: number = 0;

  distanceReflecto: number = 0;
  redDistanceReflecto: number = 0;
  fullDistanceReflecto: number = 0;
  orangeDistanceReflecto: number = 0;
  greenDistanceReflecto: number = 0;

  showSummarySignPolice: boolean = false;
  showSummarySignDirection: boolean = false;
  showSummaryGround: boolean = false;
  showSummaryLane: boolean = false;
  showSummaryReflecto: boolean = false;
  showSummarySurface: boolean = false;
  products: ProductModel[] = [];

  constructor(public breakpointObserver: BreakpointObserver, private session: Session)
  {

  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 640px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
          // this.menuItems = this.menuSideItems;
        }
      });
  }

  @Input()
  set featuresGround(features: FeatureModel[]) {
    if (features != undefined) {

      this.countItemsSigle = 0;
      this.countQualitySigle = 0;
      this.groundPaintSurface = 0;
      this.groundLinearLength =0;
      this.groundPaintLength = 0;
      this.groundEstimationPrice = 0;

      let count = 0;

      features.forEach((feature) => {

        let featureModel: FeatureModel = feature as FeatureModel;
        let featureObject: FeatureObjectElementModel = featureModel.properties as FeatureObjectElementModel;
        if (featureObject.elementProperties === undefined) {
          featureObject.elementProperties = JSON.parse(featureObject["element_properties"]);
        }
        if (featureObject["elementType"] === ElementTypesEnum.ELEMENT_TYPE_GROUND || featureObject.elementType === ElementTypesEnum.ELEMENT_TYPE_GROUND) {
           this.countItemsSigle = this.countItemsSigle + 1;
           this.countQualitySigle = featureObject.elementQuality + this.countQualitySigle;
        }

        if (featureObject.elementProperties["metrics"] !== undefined){
          if (featureObject.elementProperties["metrics"].painted_surface)
          this.groundPaintSurface = this.groundPaintSurface + featureObject.elementProperties["metrics"].painted_surface;

          if (featureObject.elementProperties["metrics"].length)
          this.groundLinearLength = this.groundLinearLength + featureObject.elementProperties["metrics"].length;

          if (featureObject.elementProperties["metrics"].painted_length)
          this.groundPaintLength = this.groundPaintLength + featureObject.elementProperties["metrics"].painted_length;
        }

        if (featureObject.elementPricingValue != undefined) {
          if (featureObject.elementPricingValue > 0) {
            this.groundEstimationPrice += featureObject.elementPricingValue;
          } else {
            count++;
          }
        }
      });
    }
    if (this.groundPaintLength !== 0
        || this.groundLinearLength !== 0
        || this.groundPaintSurface !== 0){
      this.showSummarySurface = true;
    } else {
      this.showSummarySurface = false
    }

    if (this.countItemsSigle !== 0) {
      this.countQualitySigle =this.countQualitySigle / this.countItemsSigle;
      this.showSummaryGround = true;
    } else {
      this.showSummaryGround = false;
    }
  }

  @Input()
  set featuresLane(features: FeatureModel[]) {
    if (features != undefined) {

      this.lanePaintSurface = 0;
      this.laneLinearLength =0;
      this.lanePaintLength = 0;
      this.laneEstimationPrice = 0;

      let count = 0;
      features.forEach((feature) => {

        let featureModel: FeatureModel = feature as FeatureModel;
        let featureObject: FeatureObjectElementModel = featureModel.properties as FeatureObjectElementModel;

        if (featureObject.elementProperties["metrics"] !== undefined){
          if (featureObject.elementProperties["metrics"].painted_surface)
            this.lanePaintSurface = this.lanePaintSurface + featureObject.elementProperties["metrics"].painted_surface;

          if (featureObject.elementProperties["metrics"].length)
            this.laneLinearLength = this.laneLinearLength + featureObject.elementProperties["metrics"].length;

          if (featureObject.elementProperties["metrics"].painted_length)
            this.lanePaintLength = this.lanePaintLength + featureObject.elementProperties["metrics"].painted_length;
        }

        if (featureObject.elementPricingValue != undefined) {
          if (featureObject.elementPricingValue > 0) {
            this.laneEstimationPrice += featureObject.elementPricingValue;
          } else {
            count++;
          }
        }
      });
    }
    if (this.lanePaintLength !== 0
      || this.laneLinearLength !== 0
      || this.lanePaintSurface !== 0){
      this.showSummaryLane = true;
    } else {
      this.showSummaryLane = false;
    }
  }

  @Input()
  set featuresSign(features: FeatureModel[]) {
    if (features != undefined) {

      this.countQualityDirection = 0;
      this.countItemsDirection = 0;
      this.countQualityPolice = 0;
      this.countItemsPolice = 0;
      this.countItemsDirection = 0;

      features.forEach((feature) => {


        let featureModel: FeatureModel = feature as FeatureModel;
        let featureObject: FeatureObjectElementModel = featureModel.properties as FeatureObjectElementModel;

        if (featureObject.elementCategory === 'D'){
          this.countItemsDirection = this.countItemsDirection + 1;
          this.countQualityDirection = featureObject.elementQuality + this.countQualityDirection;
        } else {
          this.countItemsPolice = this.countItemsPolice + 1;
          this.countQualityPolice = featureObject.elementQuality + this.countQualityPolice;
        }
      })
    }

    if (this.countItemsDirection !== 0){
      this.countQualityDirection = this.countQualityDirection / this.countItemsDirection;
      this.showSummarySignDirection = true;
    }
    else {
      this.showSummarySignDirection = false;
    }
    if (this.countItemsPolice !== 0) {
      this.countQualityPolice = this.countQualityPolice / this.countItemsPolice;
      this.showSummarySignPolice = true;
    } else {
      this.showSummarySignPolice = false;
    }
  }

  @Input()
  set distanceRedReflecto(redDistance: number){
    this.redDistanceReflecto = redDistance;
  }
  @Input()
  set distanceFullReflecto(fullDistance: number){
    this.fullDistanceReflecto = fullDistance;
    this.distanceReflecto = this.fullDistanceReflecto;
  }

  @Input()
  set featuresReflecto(features: FeatureModel[]) {
    if (features != undefined) {
      this.distanceReflecto = 0;
      this.countItemsReflecto = features.length;
      this.countQualityReflecto = 0;

      features.forEach((feature) => {
        let featureModel: FeatureModel = feature as FeatureModel;
        let featureObject: FeatureObjectElementModel = featureModel.properties as FeatureObjectElementModel;
        // console.log(featureObject);

        if (featureObject.elementType === ElementTypesEnum.ELEMENT_TYPE_REFLECTO){
          // this.countItemsReflecto = this.countItemsReflecto + 1;
          this.countQualityReflecto = featureObject.elementQuality + this.countQualityReflecto;
          // this.distanceReflecto = this.distanceReflecto + turf.length(featureModel.geometry, {units:'kilometers'})
        }
      });

    }

    if (this.countQualityReflecto !== 0){
      this.countQualityReflecto = this.countQualityReflecto / this.countItemsReflecto;
      this.showSummaryReflecto = true;
    }
    else {
      this.showSummaryReflecto = false;
    }
  }
}
