import {ISerializable} from '../iserializable';
import {ProductModel} from "./product-model";
import {FeatureModel} from "../geojson/feature-model";
import {FeatureObjectElementModel} from "../geojson/feature-object-element-model";
import {FeatureTypesEnum} from "../../enums/feature-types-enum.enum";
import {ElementTypesEnum} from "../../enums/element-types-enum.enum";
import {ObjectUtils} from "../../helpers/object.utils";
import {StringUtils} from "../../helpers/string.utils";

export class PricingGridModel implements  ISerializable {

  public gridCode: string;
  public gridName: string;
  public gridComment: string;
  public products: ProductModel[];

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.products != undefined) {
      this.products = input.products.map(product => new ProductModel().deserialize(product));
    }

    return this;
  }

  serialize() {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }
}
