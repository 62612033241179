import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {Session} from "./globals/session";

import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';

import {GeojsonService} from "./services/geojson.service";
import {ModelingService} from "./services/modeling.service";

import {AutoCompleteModule} from 'primeng/autocomplete';
import {SidebarModule} from 'primeng/sidebar';
import {MenubarModule} from 'primeng/menubar';
import { PanelMenuModule} from "primeng/panelmenu";
import { MenuModule } from "primeng/menu";

import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {DragDropModule} from 'primeng/dragdrop';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {ChipsModule} from 'primeng/chips';
import {AccordionModule} from 'primeng/accordion';
import {TooltipModule} from 'primeng/tooltip';
import {SliderModule} from 'primeng/slider';
import {OrderListModule} from 'primeng/orderlist';
import {DataViewModule} from 'primeng/dataview';
import {PanelModule} from 'primeng/panel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ProgressBarModule} from 'primeng/progressbar';
import {ToastModule} from "primeng/toast";

import {SpinnerComponent} from './components/main/spinner/spinner.component';
import {DashToDowndashPipe} from "./globals/pipes/dashToDowndashPipe";
import {ElementQualityPipe} from "./globals/pipes/element-quality.pipe";
import {FilterByFieldValuePipe} from "./globals/pipes/filter-by-field-value.pipe";

import { SplitButtonModule} from "primeng/splitbutton";
import {ConfirmDialogModule} from "primeng/confirmdialog";


import {SafeUrlPipe} from "./globals/pipes/safe-url.pipe";


import {CityMapComponent} from "./components/city-map/city-map.component";
import { MenuComponent } from './components/menu/menu.component';
import {AppRoutingModule} from "./app-routing.module";
import { HomeComponent } from './components/home/home.component';

import {TreeTableModule} from "primeng/treetable";

import {CardModule} from 'primeng/card';
import {SummaryComponent} from "./components/summary/summary.component";
import {MapPageComponent} from "./components/home/map-page/map-page.component";

import {ListGroundComponent} from "./components/city-map/dialog-ground/list-ground/list-ground.component";
import {DialogGroundComponent} from "./components/city-map/dialog-ground/dialog-ground.component";
import {ExportService} from "./services/export.service";
import {ListSignComponent} from "./components/city-map/dialog-sign/list-sign/list-sign.component";
import {DialogSignComponent} from "./components/city-map/dialog-sign/dialog-sign.component";
import { DialogReflectoComponent } from './components/city-map/dialog-reflecto/dialog-reflecto.component';
import { ListReflectoComponent } from './components/city-map/dialog-reflecto/list-reflecto/list-reflecto.component';
import {ElementNameGroundPipe} from "./globals/pipes/element-name-ground.pipe";
import {ElementGroupGroundPipe} from "./globals/pipes/element-group-ground.pipe";
import { ElementAxeGroundPipe } from './globals/pipes/element-axe-ground.pipe';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { MegaMenuModule, ProgressSpinnerModule, SlideMenuModule } from 'primeng';
import { HttpCredentialsRequestInterceptor } from './globals/interceptors/HttpCredentialsRequestInterceptor';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    DashToDowndashPipe,
    FilterByFieldValuePipe,
    ElementQualityPipe,
    ElementGroupGroundPipe,
    ElementNameGroundPipe,
    SafeUrlPipe,
    LoginComponent,
    CityMapComponent,
    SummaryComponent,
    MenuComponent,
    HomeComponent,
    MapPageComponent,
    ListGroundComponent,
    ListSignComponent,
    DialogGroundComponent,
    DialogSignComponent,
    DialogReflectoComponent,
    ListReflectoComponent,
    ElementAxeGroundPipe
  ],
  imports: [
    SlideMenuModule,
    ProgressSpinnerModule,
    AppRoutingModule,
    AccordionModule,
    AutoCompleteModule,
    ButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CheckboxModule,
    ChipsModule,
    DialogModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SidebarModule,
    TableModule,
    TooltipModule,
    SliderModule,
    OrderListModule,
    DataViewModule,
    PanelModule,
    SelectButtonModule,
    DropdownModule,
    RadioButtonModule,
    ProgressBarModule,
    ToastModule,
    SplitButtonModule,
    ConfirmDialogModule,
    CardModule,
    MenubarModule,
    PanelMenuModule,
    TreeTableModule,
    MenuModule,
    MegaMenuModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      useRefreshTokens:true,
      redirectUri: window.location.href,
      cacheLocation: 'localstorage',
      audience: environment.auth.audience,
      httpInterceptor: {
        allowedList: [
          {
            uri : environment.auth.apiUri,
            tokenOptions: {
              audience: environment.auth.audience,
            }

          }
        ]
      }
    }),
  ],
  providers: [
    ElementGroupGroundPipe,
    ElementNameGroundPipe,
    ElementAxeGroundPipe,
    Session,
    GeojsonService,
    ModelingService,
    ExportService,
    MessageService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpCredentialsRequestInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
