import {IFeatureProperties} from './ifeature-properties';
import {ISerializable} from '../iserializable';

export class FeatureObjectSnapshotModel implements IFeatureProperties, ISerializable {

  public featureType: string;
  public snapshotType: string;
  public snapshotIdentifier: string;
  public snapshotName: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson(): any {
    return JSON.parse(this.serialize());
  }

  toExcel(): any {
    let item: any = {};

    item.snapshotType = this.snapshotType;
    item.snapshotIdentifier = this.snapshotIdentifier;
    item.snapshotIdentifier = this.snapshotIdentifier;

    return item;
  }
}
