import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Output() changeGrid = new EventEmitter<string>();

  isMatched;
  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 1023px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
        }
      });
  }

  changeGridCode(e){
    this.changeGrid.emit(e);
  }

}
