import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  showSpinner: boolean = false;
  _origins: string[] = [];

  constructor(
  ) { }

  ngOnInit() {

  }

  addOperation(origin:string) {
    this._origins.push(origin);
    this._toggle();
  }

  removeOperation(origin:string) {
    while (this._origins.indexOf(origin) > -1) {
      this._origins.splice(this._origins.indexOf(origin), 1 );
    }
    this._toggle();
  }

  _toggle() {
    this.showSpinner = (this._origins.length > 0);
  }
}
