import {Component, Input, OnInit} from '@angular/core';
import {Session} from "../../../../globals/session";
import {TreeNode} from 'primeng/api';
import {FeatureModel} from "../../../../models/geojson/feature-model";
import {NumberUtils} from "../../../../helpers/number.utils";
import {ElementGroupGroundPipe} from "../../../../globals/pipes/element-group-ground.pipe";
import {MasterDataService} from "../../../../services/master-data.service";
import {ElementClassCollectionModel} from "../../../../models/masterData/element-class-collection-model";
import {ElementTypesEnum} from "../../../../enums/element-types-enum.enum";

@Component({
  selector: 'app-list-ground',
  templateUrl: './list-ground.component.html',
  styleUrls: ['./list-ground.component.css'],
})
export class ListGroundComponent implements OnInit {

  _isVisible: boolean = false;

  _rootNode:any[] = [];
  _treeNode:TreeNode[] = [];

  currentDisplayName: string;

  constructor(private session: Session, private masterdataService: MasterDataService) {

  }

  ngOnInit() {

  }

  @Input()
  set features(features: FeatureModel[]) {
    this._treeNode = [];
    this._rootNode = [];

    if (features != undefined) {

      // Group by Class
      features.forEach((feature) => {

        let node:TreeNode = {};
        node.data = feature.properties;

        let elementGroup: string = node.data['elementClass'];

        // Add metrics
        if (node.data.elementProperties != undefined
              && node.data.elementProperties.metrics != undefined) {
          node.data.surface = NumberUtils.notNull(node.data.elementProperties.metrics["surface"]);
          node.data.painted_surface = NumberUtils.notNull(node.data.elementProperties.metrics["painted_surface"]);
          node.data.painted_length = NumberUtils.notNull(node.data.elementProperties.metrics["painted_length"]);
          node.data.length = NumberUtils.notNull(node.data.elementProperties.metrics["length"]);
        } else {
          node.data.surface = 0;
          node.data.painted_surface = 0;
          node.data.painted_length = 0;
          node.data.length = 0;
        }

        // Add pricing
        node.data.pricing_value = node.data.elementPricingValue;

        node.data['elementCount'] = 1;

        if (this._rootNode.includes(elementGroup)) {
          // Add to Class group
          node.data['elementName'] = this.currentDisplayName;
          let categoryPipe = new ElementGroupGroundPipe();
          node.data['elementCategory'] = categoryPipe.transform(node.data['elementCategory']);
          this._rootNode[elementGroup].children.push(node);
          this._rootNode[elementGroup].data.elementCount++;
          this._rootNode[elementGroup].data['surface'] += NumberUtils.notNull(node.data['surface']);
          this._rootNode[elementGroup].data['painted_surface'] += NumberUtils.notNull(node.data['painted_surface']);
          this._rootNode[elementGroup].data['painted_length'] += NumberUtils.notNull(node.data['painted_length']);
          this._rootNode[elementGroup].data['length'] += NumberUtils.notNull(node.data['length']);
          this._rootNode[elementGroup].data['pricing_value'] +=  NumberUtils.notNull(node.data['pricing_value']);

        } else {
          // Init new Class group
          this._rootNode.push(elementGroup);

          // temporary elementName before replace
          this.currentDisplayName = '';

          let rootValue: any = {};
          rootValue['elementGroup'] = elementGroup;
          rootValue['elementCategory'] = node.data['elementCategory'];
          rootValue['elementName'] = this.currentDisplayName;
          rootValue['elementCount'] = 1;

          // Sum metrics
          rootValue['surface'] = NumberUtils.notNull(node.data['surface']);
          rootValue['painted_surface'] = NumberUtils.notNull(node.data['painted_surface']);
          rootValue['painted_length'] = NumberUtils.notNull(node.data['painted_length']);
          rootValue['length'] = NumberUtils.notNull(node.data['length']);
          rootValue['pricing_value'] = NumberUtils.notNull(node.data['pricing_value']);

          let rootNode: TreeNode = {};
          rootNode.data = rootValue;
          rootNode.children = [];

          this._rootNode[elementGroup] = rootNode;
          node.data['elementName'] = this.currentDisplayName;
          let categoryPipe = new ElementGroupGroundPipe();
          node.data['elementCategory'] = categoryPipe.transform(node.data['elementCategory']);
          this._rootNode[elementGroup].children.push(node);
        }
      });

      this._rootNode.forEach(elementGroup => {
        this.masterdataService.getElementClassByClassName(elementGroup).subscribe((response: ElementClassCollectionModel) => {
          this._rootNode[elementGroup].data['elementName'] = response.elementClasses[0].classDescription;
          this._rootNode[elementGroup].children.forEach(child => {
            child.data['elementName'] = response.elementClasses[0].classDescription;
          });
        });
      });
      // Sort RootNode
      this._rootNode.sort((a,b) => a.localeCompare(b));

      this._rootNode.forEach((elementGroup) => {
        // Sum Quality by Class
        let sumQuality:number = 0;
        let countQuality:number = 0;
        this._rootNode[elementGroup].children.forEach((element) => {
          if (element.data.elementQuality != undefined) {
            sumQuality += element.data.elementQuality;
            countQuality++;
          }
        });
        this._rootNode[elementGroup].data['elementQuality'] = sumQuality/countQuality;

        // Push to TreeNode
        this._treeNode.push(this._rootNode[elementGroup]);
      });
    }
  }
}
