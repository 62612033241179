import {Injectable} from '@angular/core';
import {FeatureModel} from "../models/geojson/feature-model";
import {GeometryModel} from "../models/geojson/geometry-model";
import * as turf from "@turf/turf";
import {GeometryTypeEnum} from "../enums/geometry-type-enum.enum";
import {FeatureTypesEnum} from "../enums/feature-types-enum.enum";

@Injectable({
  providedIn: 'root'
})
export class StringUtils {

  constructor() {}

  static isNotEmpty(value: string): boolean {
    return (value !== undefined
          && value !== '')
  }

  static isEmpty(value: string): boolean {
    return !StringUtils.isNotEmpty(value);
  }

  static toUpperCase(value: string): string {
    if (StringUtils.isNotEmpty(value)) {
      return value.toUpperCase();
    }
    return undefined;
  }
}
