import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {MenuItem, SelectItem} from "primeng/api";
import {Session} from "../../globals/session";
import {SessionService} from "../../services/session.service";
import {SessionModel} from "../../models/session/session-model";
import {PricingGridCollectionModel} from "../../models/pricing/pricing-grid-collection-model";
import {PricingService} from "../../services/pricing.service";
import {PricingGridModel} from "../../models/pricing/pricing-grid-model";
import {CommunicationService} from "../../services/communication.service";
import {Router} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {DomainService} from 'src/app/services/domain.service';
import {environment} from "../../../environments/environment";

export class Grid{
  name: string;
  code: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

  grid: any[];
  domain;
  isMatched;
  menuItems: MenuItem[];
  userItems: any[] = [];
  menuSideItems : MenuItem[];
  pricingGrids: PricingGridModel[];
  gridCode: string;
  gridName: string;
  selectedGrid: Grid;
  grid1: SelectItem[];
  visibleSidebar1;
  loading = false;
  @Output() changeGrid = new EventEmitter<string>();

  constructor(public breakpointObserver: BreakpointObserver,
              private session: Session,
              private sessionService: SessionService,
              private pricingService: PricingService,
              private communication: CommunicationService,
              private router: Router,
              private authService: AuthService,
              private domainService: DomainService) { }

  currentSession: SessionModel = this.session.currentSession;

  ngOnInit() {

    this.breakpointObserver
      .observe(['(min-width: 1023px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMatched = false;
        } else {
          this.isMatched = true;
          // this.menuItems = this.menuSideItems;
        }
      });

    this.grid = [];
    this.gridCode= '';
    let gridByUser ='';
    if(this.currentSession.user.userProperties && this.currentSession.user.userProperties.cityroad){
      gridByUser= this.currentSession.user.userProperties.cityroad['price-grid']

    }
    this.pricingService.getPricingGrids(gridByUser).subscribe( (resp: PricingGridCollectionModel) => {
      if (resp) {
        resp.pricingGrids.forEach((pricingGridModel:PricingGridModel)  => {
            this.grid.push({label:pricingGridModel.gridName, value:{ name: pricingGridModel.gridName , code: pricingGridModel.gridCode}});
        });
        this.grid1 = this.grid;
      }
    });
    this.userItems = [
      {label: 'CityMap', icon: 'fa fa-globe', routerLink: ['map'] },
    ];

    const domainList = this.session.domains.domains.map(d=>({
      label: d.domainName,
      id: d.domainId,
      icon: this.session.currentDomain == d.domainId ?  'pi pi-check' : 'pi pi-circle-off',
      command: (ev) => {
        if(this.session.currentDomain != d.domainId){
          this.setCurrentDomain(ev)
        }
      }
    }))

    if(domainList && domainList.length > 1){
      this.userItems.push({
        label: 'Domaines',
        icon: 'fa fa-map-signs',
        items: domainList
      });
    }
    this.userItems = [
      ...this.userItems,
      {separator: true},
      {label: 'Déconnexion', icon: 'pi pi-fw pi-sign-out',
        command: () => { this.logout();}
       },
    ]
  }

  setCurrentDomain(event) {
    this.loading = true;
    this.domainService.setCurrentDomain(event.item.id).subscribe((_res) => {
      this.authService.getAccessTokenSilently({audience: environment.auth.audience})
        .subscribe((_token) => {
          window.location.reload();
        });
    }, (_) => this.loading = false)
  }


  logout() {
    let getUrl = window.location;
    let baseUrl = getUrl.protocol + "//" + getUrl.host;
    this.authService.logout({
      returnTo: baseUrl,
    })
  }

  onChangeCode(code){
    // TODO : Utiliser la session pour le passage des event (ex. Workspace)
    this.communication.emitChange(code);
  }

}
