import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionService} from "../../services/session.service";
import {Session} from "../../globals/session";
import {DomainCollectionModel} from "../../models/session/domain-collection-model";
import {DomainModel} from "../../models/session/domain-model";
import {StringUtils} from "../../helpers/string.utils";
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as cookie from 'js-cookie'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private session: Session){
  }

  ngOnInit() {
      this.logWithAuth0();
  }

  logWithAuth0(){
    this.authService.isAuthenticated$.subscribe(isAuth=>{
      if(!isAuth){
        this.login();
      } else {
        this.authService.getAccessTokenSilently({audience:environment.auth.audience}).subscribe(t=>{
          this.session.token = t;
          this.sessionService.getSession().pipe(map(session=>{
            this.sessionService.getDomains().subscribe(domains=>{
              const currentDomainCookie = parseInt(cookie.get('domain'));
              if(currentDomainCookie){
                this.session.currentDomain = domains.domains.find(p => p.domainId == currentDomainCookie).domainId;
              }
              this.session.loadSession(session);
              this.session.domains = domains,
              this.session.isLogged = true;
              this.session.isLogging = false;
              this.session.sessionLocale = this.session.currentSession.user.userLocale;
              this.session.sessionProperties = this.session.currentSession.user.userProperties;
            })
          })).subscribe()
        })
      }
    })
  }

  login(){
    let location = window.location.href.split("#")[0].split("/").slice(0,-1).join("/")
    this.authService.loginWithRedirect({redirect_uri: location})
  }

    // get values from Url
    getUrlVars() { 
      const vars = {};
      window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        return vars[key] = value;
      });
      return vars;
    }

  // add values by default
  getUrlParam(parameter, defaultvalue) {
    let urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

}
