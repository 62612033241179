
<div class="ui-card-body" >
    <p-card *ngIf="(showSummarySignPolice && showSign && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px'}">
      <p-header class="headerCard">
        {{countItemsPolice | number }}
      </p-header>
        <h6>PANNEAUX DE POLICE</h6>
      <footer class="footerCard">
        {{countQualityPolice | number : '1.0-0'}} %
      </footer>
      <h6 style="padding-bottom: 10px">INDICE QUALITE</h6>
    </p-card>
     <p-card *ngIf="(showSummarySignDirection && showSign && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'margin-top': '20px', 'padding-top':'5px', 'width': '170px','margin-top': '10px'}">
     <p-header class="headerCard">
       {{countItemsDirection | number }}
      </p-header>
      <h6>PANNEAUX DE DIRECTION</h6>
      <footer class="footerCard">
        {{countQualityDirection | number :'1.0-0'}} %
      </footer>
      <h6 style="padding-bottom: 10px">INDICE QUALITE</h6>
    </p-card>




   <p-card *ngIf="(showSummaryGround && showGround && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
      <p-header class="headerCard">
       {{countItemsSigle | number }}
      </p-header>
     <h6>SIGLES</h6>
     <footer class="footerCard">
       {{countQualitySigle | number: '1.0-0' }} %
      </footer>
     <h6 style="padding-bottom: 10px">INDICE QUALITE</h6>
   </p-card>
  <p-card *ngIf="(showSummarySurface && showGround && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
    <p-header class="headerCard">
      {{groundPaintSurface | number :'1.0-0'}} m2
    </p-header>
    <h6>SURFACES PEINTES</h6>
    <footer class="footerCard">
      {{groundLinearLength | number :'1.0-0' }} m
    </footer>
    <h6 style="padding-bottom: 10px">LONGUEURS LINEAIRES</h6>
  </p-card>

  <p-card *ngIf="(showSummarySurface && showGround && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
    <p-header class="headerCard">
      {{groundPaintLength | number :'1.0-0'}} m
    </p-header>
    <h6>LONGUEURS PEINTES</h6>
    <footer class="footerCard">
      {{groundEstimationPrice | number: '1.0-0' }} €
    </footer>
    <h6 style="padding-bottom: 10px">ESTIMATION PRIX</h6>
  </p-card>





  <p-card *ngIf="(showSummaryLane && showLane && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
    <footer class="footerCard">
      {{laneLinearLength | number :'1.0-0' }} m
    </footer>
    <h6 style="padding-bottom: 10px">LONGUEURS LINEAIRES</h6>
  </p-card>

  <p-card *ngIf="(showSummaryLane && showLane && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
    <p-header class="headerCard">
      {{lanePaintLength | number :'1.0-0'}} m
    </p-header>
    <h6>LONGUEURS PEINTES</h6>
    <footer class="footerCard">
      {{laneEstimationPrice | number: '1.0-0' }} €
    </footer>
    <h6 style="padding-bottom: 10px">ESTIMATION PRIX</h6>
  </p-card>





  <p-card *ngIf="(showSummaryReflecto && showReflecto && !isMatched)" class="ui-card" [style]="{'background-color':'#3053CD', 'box-shadow':'none', 'color' :'white', 'width': '170px', 'padding-top':'5px','margin-top': '10px'}">
    <p-header class="headerCard">
      {{fullDistanceReflecto | number :'1.1-1'}} Km
    </p-header>
    <h6>KM LINEAIRE</h6>
    <div class="footerCard">
      {{redDistanceReflecto | number :'1.1-1'}} Km
    </div>
    <h6 >Mauvaise qualité </h6>
    <footer class="footerCard">
      {{countQualityReflecto | number :'1.0-0'}}
    </footer>
    <h6 style="padding-bottom: 10px">REFLECTOMETRIE</h6>
  </p-card>

</div>

