import {Component, Input, OnInit} from '@angular/core';
import {Session} from "../../../../globals/session";
import {TreeNode} from 'primeng/api';
import {FeatureModel} from "../../../../models/geojson/feature-model";

@Component({
  selector: 'app-list-reflecto',
  templateUrl: './list-reflecto.component.html',
  styleUrls: ['./list-reflecto.component.css']
})
export class ListReflectoComponent implements OnInit {

  _isVisible: boolean = false;

  _rootNode:any[] = [];
  _treeNode:TreeNode[] = [];

  constructor(private session: Session) { }

  ngOnInit() {
  }

  @Input()
  set features(features: FeatureModel[]) {
    console.log(features);
    this._treeNode = [];
    this._rootNode = [];

    if (features != undefined) {

      // Group by Class
      features.forEach((feature) => {

        let node:TreeNode = {};
        node.data = feature.properties;

        let nodeProperties: TreeNode ={};
        nodeProperties.data = node.data.elementProperties;

        let elementGroup: string = node.data['elementClass'];

        node.data['RL_Max'] = nodeProperties.data['RL_Max'];
        node.data['RL_Min'] = nodeProperties.data['RL_Min'];

        node.data['elementDisplay'] = node.data['elementClass'] + " (" + node.data['elementName'] + ")"

        if (this._rootNode.includes(elementGroup)) {

           this._rootNode[elementGroup].children.push(node);
           this._rootNode[elementGroup].data.elementCount++;

           if (this._rootNode[elementGroup].data['RL_Min'] > node.data['RL_Min']) {
             this._rootNode[elementGroup].data['RL_Min'] = node.data['RL_Min'];
           }

          if (this._rootNode[elementGroup].data['RL_Max'] < node.data['RL_Max']) {
            this._rootNode[elementGroup].data['RL_Max'] = node.data['RL_Max'];
          }

         } else {
           this._rootNode.push(elementGroup);

            let rootValue: any = {};

            rootValue['elementGroup'] = elementGroup;
            rootValue['elementDisplay'] = elementGroup;

            rootValue['RL_Max'] = node.data['RL_Max'];
            rootValue['RL_Min'] = node.data['RL_Min'];

            let rootNode: TreeNode = {};
            rootNode.data = rootValue;
            rootNode.children = [];

            this._rootNode[elementGroup] = rootNode;
            this._rootNode[elementGroup].children.push(node);
         }
      });

      // Sort RootNode
      this._rootNode.sort((a,b) => a.localeCompare(b));

      this._rootNode.forEach((elementGroup) => {
        // Quality by Class
        let sumQuality:number = 0;
        let countQuality:number = 0;
        this._rootNode[elementGroup].children.forEach((element) => {
          if (element.data.elementQuality != undefined) {
            sumQuality += element.data.elementQuality;
            countQuality++;
          }
        });
        this._rootNode[elementGroup].data['elementQuality'] = sumQuality/countQuality;

        // Push to TreeNode
        this._treeNode.push(this._rootNode[elementGroup]);
      });
    }
  }

}
