import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Session} from "../../../globals/session";
import {FeatureModel} from "../../../models/geojson/feature-model";
import {ExportService} from "../../../services/export.service";

@Component({
  selector: 'app-dialog-reflecto',
  templateUrl: './dialog-reflecto.component.html',
  styleUrls: ['./dialog-reflecto.component.css']
})
export class DialogReflectoComponent implements OnInit {

  @Input() features:FeatureModel[] = [];

  @Output() visibleChange = new EventEmitter<boolean>();

  _visible: boolean = false;

  constructor(session: Session,
              private exportService: ExportService) { }

  ngOnInit() {
  }

  @Input()
  set visible(show: boolean) {
    this._visible = show;
    this.visibleChange.emit(this._visible)
  }
  get visible(): boolean {
    return this._visible;
  }

  show(show: boolean) {
    this.visible = show;
  }
}
